import React, { FunctionComponent, ReactNode, useState } from "react";
import { styled } from "@mui/system";
import {
  CartesianGrid,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  DefaultLegendContentProps,
} from "recharts";
import { COLORS, FONTS, StatusColors } from "../../common/consts";
import {
  EmailStatuses,
  ExtendedEmailStatuses,
  OutreachEmailStatuses,
} from "../../types/organizations";
import CustomTooltip from "../../components/Display/CustomTooltip";
import { formatShortDate } from "../../common/utils/date-time";
import { calculateStatsPercentages } from "../../common/utils/number";
import Button, { ButtonVariants } from "../../components/Display/Button";
import { LegendShapeWrapper } from "../../components/Display/CustomTooltip";
import {
  getShapeByEntryName,
  getColorByEntryName,
  getLabelByEntryName,
} from "../../components/Display/CustomTooltip";

const StyledContainer = styled("div")`
  width: calc(100% - 2rem);
  height: 23.5rem;
  min-width: 100px;
`;

const StyledList = styled("div")`
  justify-content: flex-end;
  align-items: center;
`;

interface MainChartDataEntry {
  date: string;
  [OutreachEmailStatuses.Valid]: number;
  [OutreachEmailStatuses.Invalid]: number;
  [OutreachEmailStatuses.Questionable]: number;
  [ExtendedEmailStatuses.NoEmail]: number;
  [ExtendedEmailStatuses.EnrichedByBoring]: number;
}

export type MainChartData = MainChartDataEntry[];

interface Props {
  data: MainChartData;
  children?: ReactNode;
}

const renderLegend = (
  props: Omit<DefaultLegendContentProps, "onClick"> & {
    onClick: (value: EmailStatuses) => void;
    visibleLines: Record<EmailStatuses, boolean>;
  }
) => {
  const { payload, onClick, visibleLines } = props;

  if (!payload) return;

  return (
    <StyledList className="w-100 mb-4 d-flex">
      {payload.map(({ value }, index) => {
        const ShapeComponent = getShapeByEntryName(value);
        const entryColor = getColorByEntryName(value);
        const status = value as EmailStatuses;

        return (
          <div key={`item-${index}`} onClick={() => onClick(status)}>
            <Button
              key={`item-${index}`}
              variant={ButtonVariants.pill}
              active={visibleLines[status]}
              startIcon={
                <LegendShapeWrapper
                  className="mr-0"
                  color={
                    visibleLines[status]
                      ? COLORS.foregroundSecondaryLight
                      : entryColor
                  }
                >
                  <ShapeComponent />
                </LegendShapeWrapper>
              }
            >
              {getLabelByEntryName(value)}
            </Button>
          </div>
        );
      })}
    </StyledList>
  );
};

const MainChart: FunctionComponent<Props> = ({ data, children }) => {
  const [visibleLines, setVisibleLines] = useState<
    Record<EmailStatuses, boolean>
  >({
    [OutreachEmailStatuses.Valid]: false,
    [OutreachEmailStatuses.Invalid]: false,
    [OutreachEmailStatuses.Questionable]: false,
    [ExtendedEmailStatuses.NoEmail]: false,
    [ExtendedEmailStatuses.EnrichedByBoring]: false,
  });

  const processedData = data.map((entry) => {
    const totalValue =
      entry[OutreachEmailStatuses.Valid] +
      entry[OutreachEmailStatuses.Invalid] +
      entry[OutreachEmailStatuses.Questionable] +
      entry[ExtendedEmailStatuses.NoEmail] +
      entry[ExtendedEmailStatuses.EnrichedByBoring];

    const percentages = calculateStatsPercentages({
      prospectsChecked: totalValue,
      valid: entry[OutreachEmailStatuses.Valid],
      invalid: entry[OutreachEmailStatuses.Invalid],
      questionable: entry[OutreachEmailStatuses.Questionable],
      noEmail: entry[ExtendedEmailStatuses.NoEmail],
      enrichedByBoring: entry[ExtendedEmailStatuses.EnrichedByBoring],
    });
    return { ...entry, percentages };
  });

  const toggleLineVisibility = (key: EmailStatuses) => {
    setVisibleLines((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <StyledContainer>
      {children}
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={processedData}>
          <CartesianGrid stroke="transparent" />
          <XAxis
            dataKey="date"
            tickLine={false}
            axisLine={{ stroke: COLORS.borderSecondary }}
            tick={{
              fill: COLORS.borderSecondary,
              fontSize: FONTS.small,
            }}
            tickFormatter={formatShortDate}
            padding={{ left: 20, right: 20 }}
          />
          <YAxis
            includeHidden
            tickLine={false}
            axisLine={{ stroke: COLORS.borderSecondary }}
            tick={{
              fill: COLORS.borderSecondary,
              fontSize: FONTS.small,
            }}
            padding={{ top: 5, bottom: 5 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign="top"
            content={(props) =>
              renderLegend({
                ...props,
                onClick: toggleLineVisibility,
                visibleLines,
              })
            }
          />
          <Line
            type="linear"
            dataKey={OutreachEmailStatuses.Valid}
            stroke={StatusColors[OutreachEmailStatuses.Valid]}
            dot={false}
            isAnimationActive={true}
            strokeWidth={2}
            hide={visibleLines[OutreachEmailStatuses.Valid]}
          />
          <Line
            type="linear"
            dataKey={OutreachEmailStatuses.Invalid}
            stroke={StatusColors[OutreachEmailStatuses.Invalid]}
            dot={false}
            isAnimationActive={true}
            strokeWidth={2}
            hide={visibleLines[OutreachEmailStatuses.Invalid]}
          />
          <Line
            type="linear"
            dataKey={OutreachEmailStatuses.Questionable}
            stroke={StatusColors[OutreachEmailStatuses.Questionable]}
            dot={false}
            isAnimationActive={true}
            strokeWidth={2}
            hide={visibleLines[OutreachEmailStatuses.Questionable]}
          />
          <Line
            type="linear"
            dataKey={ExtendedEmailStatuses.NoEmail}
            stroke={StatusColors[ExtendedEmailStatuses.NoEmail]}
            dot={false}
            isAnimationActive={true}
            strokeWidth={2}
            hide={visibleLines[ExtendedEmailStatuses.NoEmail]}
          />
          <Line
            type="linear"
            dataKey={ExtendedEmailStatuses.EnrichedByBoring}
            stroke={StatusColors[ExtendedEmailStatuses.EnrichedByBoring]}
            dot={false}
            isAnimationActive={true}
            strokeWidth={2}
            hide={visibleLines[ExtendedEmailStatuses.EnrichedByBoring]}
          />
          {/* <Line
            type="linear"
            dataKey="Enriched by boring"
            stroke={StatusColors["Enriched by boring"]}
          /> */}
        </LineChart>
      </ResponsiveContainer>
    </StyledContainer>
  );
};

export default MainChart;
