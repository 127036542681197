export enum FirebaseEnv {
  DEVELOPMENT = "DEVELOPMENT",
  STAGING = "STAGING",
  PRODUCTION = "PRODUCTION",
}

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

const stagingConfig: FirebaseConfig = {
  apiKey: "AIzaSyDYDNwjoww9v7vrn-lk-H2Gt4PGuOpk_Ss",
  authDomain: "higene-app.firebaseapp.com",
  projectId: "higene-app",
  storageBucket: "higene-app.appspot.com",
  messagingSenderId: "981244961972",
  appId: "1:981244961972:web:b7d28f530ce58c6477e3e1",
  measurementId: "G-0GBNLSSSG5",
};

const productionConfig: FirebaseConfig = {
  apiKey: "AIzaSyBOmR0-a-58-2O9fv7qlXZFzC5t3UJGkM8",
  authDomain: "boring-plugins.firebaseapp.com",
  projectId: "boring-plugins",
  storageBucket: "boring-plugins.appspot.com",
  messagingSenderId: "785397211993",
  appId: "1:785397211993:web:50100231e94c71ce1ee248",
  measurementId: "G-2F6B13TSGC",
};

let firebaseConfig = stagingConfig;

if (process.env.REACT_APP_FIREBASE_ENV === FirebaseEnv.PRODUCTION) {
  firebaseConfig = productionConfig;
}

export const isDevelopment =
  process.env.REACT_APP_FIREBASE_ENV === FirebaseEnv.DEVELOPMENT;

export const isStaging =
  process.env.REACT_APP_FIREBASE_ENV === FirebaseEnv.STAGING;

export const isProduction =
  process.env.REACT_APP_FIREBASE_ENV === FirebaseEnv.PRODUCTION;

export { firebaseConfig };
