import React, { FunctionComponent } from "react";
import Link, { LinkColors } from "../../components/Display/Link";

const TermsFooter: FunctionComponent = () => {
  return (
    <div className="d-flex mt-4 text-small justify-content-evenly">
      <Link
        external
        path="https://boringplugins.com/terms-of-service"
        color={LinkColors.secondary}
      >
        Terms
      </Link>
      <Link
        external
        path="https://boringplugins.com/privacy-policy"
        color={LinkColors.secondary}
      >
        Privacy
      </Link>
    </div>
  );
};

export default TermsFooter;
