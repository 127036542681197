import { selector } from "recoil";
import { signupFormAtom, loginFormAtom, resetPasswordFormAtom } from "./atoms";

export const canSubmitSignupFormSelector = selector<boolean>({
  key: "canSubmitStep2Selector",
  get: ({ get }) => {
    const signupForm = get(signupFormAtom);
    const { name, email, password, passwordConfirmation } = signupForm;

    return (
      name.length > 0 &&
      email.length > 0 &&
      password.length >= 8 &&
      password === passwordConfirmation
    );
  },
});

export const canSubmitLoginFormSelector = selector<boolean>({
  key: "canSubmitLoginFormSelector",
  get: ({ get }) => {
    const loginForm = get(loginFormAtom);
    const { email, password } = loginForm;

    return email.length > 0 && password.length > 0;
  },
});

export const canSubmitResetpasswordFormSelector = selector<boolean>({
  key: "canSubmitResetpasswordFormSelector",
  get: ({ get }) => {
    const resetPasswordForm = get(resetPasswordFormAtom);
    const { email } = resetPasswordForm;

    return email.length > 0;
  },
});
