import React, { FunctionComponent, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { setupProspectWebhook, signUserOut } from "../../services/firebase";
import { signedInUserAtom } from "../../state/atoms";
import { Errors } from "../../types/errors";
import Card from "../../components/Display/Card";
import Tabs from "../../components/Display/Tabs";
import Button, { ButtonVariants } from "../../components/Display/Button";
import FormHelper, { Severity } from "../../components/DataInput/FormHelper";
import Text, { TextVariant } from "../../components/Display/Text";
import Insights from "./Insights";
import Animation from "../../components/Display/Animation";

const Dashboard: FunctionComponent = () => {
  const signedInUser = useRecoilValue(signedInUserAtom);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showSetupWebhookButton, setShowSetupWebhookButton] = useState(true);
  const [error, setError] = useState("");

  const {
    firstName,
    lastName,
    organizationId,
    organizationName,
    isProspectWebhookCreated,
  } = signedInUser || {};

  const handleSetupWebhook = async () => {
    setIsProcessing(true);
    try {
      const result = await setupProspectWebhook();
      setIsProcessing(false);
      if (result.status === 200) {
        setShowSetupWebhookButton(false);
      }
    } catch (error) {
      setError(
        `Error setting up webhook (Code ${Errors.CONNECT_OUTREACH_SET_WEBHOOK_FUNCTION_ERROR}).`
      );
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (isProspectWebhookCreated) {
      setShowSetupWebhookButton(false);
    }
  }, [isProspectWebhookCreated]);

  return showSetupWebhookButton ? (
    <Animation className="col-12">
      <div className="d-flex h-100 align-items-center justify-content-center m-auto">
        <div className="w-75 w-lg-400px">
          <Text variant={TextVariant.h4} className="mb-3 text-center">
            Connect to your Outreach account
          </Text>
          <div className="mb-3">
            <Button
              fullWidth
              variant={ButtonVariants.brand}
              type="submit"
              disabled={isProcessing}
              onClick={handleSetupWebhook}
              loading={isProcessing}
              startIcon={<i className="fa-solid fa-plug" />}
            >
              Setup webhook
            </Button>
          </div>
          <Text variant={TextVariant.small} className="text-center">
            I will continue later,{" "}
            <span onClick={signUserOut} className="btn-link cursor-pointer">
              sign out
            </span>{" "}
            for now.
          </Text>
          <FormHelper helperText={error} severity={Severity.error} />
        </div>
      </div>
    </Animation>
  ) : (
    <div className="p-0">
      <Tabs
        options={[
          {
            label: "Insights",
            value: "insights",
            children: (
              <Insights organizationId={organizationId} setError={setError} />
            ),
          },
          {
            label: "Settings",
            value: "settings",
            children: (
              <Card>
                Hi, {firstName || lastName || "stranger"}!{" "}
                {organizationName ? `${organizationName} is` : "You are"} all
                set.
                <div className="text-small mt-1">
                  <span
                    onClick={signUserOut}
                    className="btn-link cursor-pointer link"
                  >
                    Sign out
                  </span>
                </div>
              </Card>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Dashboard;
