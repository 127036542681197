export enum Errors {
  CONNECT_OUTREACH_GENERATE_URL_FUNCTION_ERROR = 1001,
  CONNECT_OUTREACH_GENERATE_URL_FUNCTION_FAILURE = 1002,
  CONNECT_OUTREACH_UPDATE_ORG_FUNCTION_ERROR = 1003,
  CONNECT_OUTREACH_UPDATE_ORG_FUNCTION_FAILURE = 1004,
  CONNECT_OUTREACH_SET_WEBHOOK_FUNCTION_ERROR = 1005,
  COMPLETE_PROFILE_DB_FAILURE = 2001,
  SUBSCRIBE_TO_STATS_DB_FAILURE = 3001,
  AUTH_LOGIN_ERROR = 4001,
  AUTH_LOGIN_FAILURE = 4002,
  AUTH_SIGNUP_ERROR = 4003,
  AUTH_SIGNUP_FAILURE = 4004,
  AUTH_RESET_PASSWORD_FAILURE = 4005,
}
