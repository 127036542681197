import React, { FunctionComponent, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Helmet } from "react-helmet-async";

import { signUserIn } from "../../services/firebase";
import { loginFormAtom } from "../../state/atoms";
import { canSubmitLoginFormSelector } from "../../state/selectors";
import { Errors } from "../../types/errors";
import mainImage from "../../assets/images/main-image.svg";
import TermsFooter from "./TermsFooter";
import Card from "../../components/Display/Card";
import Button, { ButtonVariants } from "../../components/Display/Button";
import Input from "../../components/DataInput/Input";
import FormHelper, { Severity } from "../../components/DataInput/FormHelper";
import Text, { TextVariant } from "../../components/Display/Text";
import Link from "../../components/Display/Link";
import Animation from "../../components/Display/Animation";

const Login: FunctionComponent = () => {
  const [loginForm, setLoginForm] = useRecoilState(loginFormAtom);
  const canSubmitLoginForm = useRecoilValue(canSubmitLoginFormSelector);

  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (canSubmitLoginForm) {
      try {
        setIsProcessing(true);
        setError("");
        const { user } = await signUserIn(loginForm.email, loginForm.password);

        if (!user) {
          setError(
            `Error logging into account (Code ${Errors.AUTH_LOGIN_ERROR}). Please validate the data you have entered, and try again.`
          );

          return;
        }
      } catch (error) {
        setError(
          `Error logging into account (Code ${Errors.AUTH_LOGIN_FAILURE}). Please validate the data you have entered, and try again.`
        );
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const buttons = (
    <>
      <Link path="/auth/signup">Sign up</Link>
      <Link path="/auth/reset-password">Reset password</Link>
      <Button
        variant={ButtonVariants.brand}
        type="submit"
        disabled={!canSubmitLoginForm || isProcessing}
        loading={isProcessing}
      >
        Let&apos;s go!
      </Button>
    </>
  );

  return (
    <Card>
      <Animation>
        <Helmet>
          <title>Log in | Boring</title>
        </Helmet>
        <div className="d-flex col-12 h-100 align-items-center justify-content-center m-auto">
          <form className="w-75 w-lg-400px" onSubmit={onSubmit}>
            <div className="d-flex w-100 align-items-center justify-content-center">
              <img src={mainImage} className="img-fluid" alt="Boring plugins" />
            </div>
            <Text variant={TextVariant.h4} className="mb-2">
              Log in
            </Text>
            <div className="d-flex flex-column gap-3 mb-3">
              <div>
                <Input
                  label="Email address"
                  type="email"
                  placeholder="name@example.com"
                  value={loginForm.email}
                  onChange={(e) =>
                    setLoginForm({ ...loginForm, email: e.target.value })
                  }
                />
              </div>
              <div>
                <Input
                  label="Password"
                  type="password"
                  placeholder="********"
                  value={loginForm.password}
                  onChange={(e) =>
                    setLoginForm({ ...loginForm, password: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="d-none d-sm-flex justify-content-between align-items-center">
              {buttons}
            </div>
            <div className="d-flex flex-column-reverse gap-3 d-sm-none text-center">
              {buttons}
            </div>
            <FormHelper helperText={error} severity={Severity.error} />
            <TermsFooter />
          </form>
        </div>
      </Animation>
    </Card>
  );
};

export default Login;
