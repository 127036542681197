// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  User,
  UserCredential,
  NextOrObserver,
  Unsubscribe as AuthUnsubscribe,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  Unsubscribe,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  connectFirestoreEmulator,
  collection,
  doc,
  DocumentReference,
  getDoc,
  getFirestore,
  setDoc,
  SetOptions,
  DocumentSnapshot,
  DocumentData,
  onSnapshot,
  where,
  query,
  CollectionReference,
  orderBy,
  getDocs,
} from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

import {
  ExtendedEmailStatuses,
  Organization,
  OutreachEmailStatuses,
} from "../types/organizations";
import { AppUser, UID } from "../types/users";
import { firebaseConfig, isDevelopment, isProduction } from "./config";
import { getStatsDateRange } from "../common/utils/date-time";

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseFirestore = getFirestore(firebaseApp);
export const firebaseFunctions = getFunctions(firebaseApp);
firebaseFunctions.region = isProduction ? "us-central1" : "europe-west3";

// --------------------
// Emulators
// --------------------
if (isDevelopment) {
  console.warn("Running in development environment");
  connectAuthEmulator(firebaseAuth, "http://localhost:9099");
  connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
  connectFirestoreEmulator(firebaseFirestore, "localhost", 5002);
}

// --------------------
// Authentication
// --------------------

export const onAuthStateChangedHook = (
  observer: NextOrObserver<User>
): AuthUnsubscribe => onAuthStateChanged(firebaseAuth, observer);

export const signUserUp = (
  email: string,
  password: string
): Promise<UserCredential> =>
  createUserWithEmailAndPassword(firebaseAuth, email, password);

export const signUserIn = (
  email: string,
  password: string
): Promise<UserCredential> =>
  signInWithEmailAndPassword(firebaseAuth, email, password);

export const resetPassword = (email: string): Promise<void> =>
  sendPasswordResetEmail(firebaseAuth, email);

export const signUserOut = async (): Promise<void> => {
  await signOut(firebaseAuth);
};

export const reload = () => {
  window.location.reload();
};

// --------------------
// Functions
// --------------------

const getOutreachAuthUrlFunction = httpsCallable(
  firebaseFunctions,
  "getOutreachAuthUrl"
);
export const getOutreachAuthUrl = async (): Promise<{
  result?: { outreachAuthUrl: string };
  status: number;
}> => {
  const { data } = await getOutreachAuthUrlFunction();
  if (!data) {
    throw new Error("Error getting Outreach connect url");
  }
  return data as { result?: { outreachAuthUrl: string }; status: number };
};

const getMyOrganizationFunction = httpsCallable(
  firebaseFunctions,
  "getMyOrganization"
);
export const getMyOrganization = async (): Promise<{
  result?: Organization;
  status: number;
}> => {
  const { data } = await getMyOrganizationFunction();
  if (!data) {
    throw new Error("Error getting organization");
  }
  return data as { result?: Organization; status: number };
};

const validateOutreachAuthCodeFunction = httpsCallable(
  firebaseFunctions,
  "validateOutreachAuthCode"
);
export const validateOutreachAuthCode = async (
  outreachAuthCode: string
): Promise<{
  result?: Organization;
  status: number;
}> => {
  const { data } = await validateOutreachAuthCodeFunction(outreachAuthCode);
  if (!data) {
    throw new Error("Error validateOutreachAuthCode");
  }
  return data as { result?: Organization; status: number };
};

const setupProspectWebhookFunction = httpsCallable(
  firebaseFunctions,
  "setupProspectWebhook"
);
export const setupProspectWebhook = async (): Promise<{
  result?: { webhookId: string };
  status: number;
}> => {
  const { data } = await setupProspectWebhookFunction();
  if (!data) {
    throw new Error("Error setting up prospect webhook");
  }
  return data as { result?: { webhookId: string }; status: number };
};

// --------------------
// Firestore
// --------------------

const usersCollection = collection(firebaseFirestore, "users");
const statsCollection = collection(firebaseFirestore, "stats");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeUndefineds = (obj: any) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === "object") removeUndefineds(val);
    else if (val === null || val === undefined) delete obj[key];
  });
};

const setDocSafely = <T>(
  docRef: DocumentReference<T>,
  data: T,
  options?: SetOptions
) => {
  const sanitized = structuredClone(data) as T;
  removeUndefineds(sanitized);
  return setDoc(docRef, sanitized, options || {});
};

export const getAppUser = async (uid: UID): Promise<AppUser | null> => {
  const user = (
    await getDoc(doc(usersCollection, uid))
  ).data() as AppUser | null;
  return user || null;
};

export const upsertAppUser = async (user: AppUser): Promise<AppUser> => {
  await setDocSafely(doc(usersCollection, user.uid), user);
  return user;
};

export const updateAppUser = async (
  user: Partial<AppUser>
): Promise<Partial<AppUser>> => {
  await setDocSafely(doc(usersCollection, user.uid), user, { merge: true });
  return user;
};

export const subToRunStats = (
  { organizationId }: { organizationId: string },
  observer: (snapshot: DocumentSnapshot<DocumentData>) => void
): Unsubscribe => {
  const docRef: DocumentReference<DocumentData> = doc(
    statsCollection,
    organizationId
  );

  return onSnapshot<DocumentData>(
    docRef,
    { includeMetadataChanges: false },
    observer
  );
};

type Result = {
  [key: string]: {
    [key in OutreachEmailStatuses]: number;
  } & { prospectsChecked: number; enrichedByBoring: number };
};

const getUniqueProspectCountsForDateRange = async ({
  allActivity,
  startDate,
  endDate,
}: {
  allActivity: CollectionReference<DocumentData>;
  startDate: Date;
  endDate?: Date;
}) => {
  const q = query(
    allActivity,
    where("time", ">", startDate),
    where("time", "<", endDate ?? new Date()),
    orderBy("time")
  );
  const docs = await getDocs(q);

  const lastActivityByProspectId: Record<
    number,
    {
      status: OutreachEmailStatuses;
      prospectId: number;
      email?: string;
      didGuess?: boolean;
    }
  > = {};
  docs.forEach((doc) => {
    const data = doc.data() as {
      status: OutreachEmailStatuses;
      prospectId: number;
      email?: string;
      didGuess?: boolean;
    };
    const prospectId = data.prospectId;
    lastActivityByProspectId[prospectId || -1] = data;
  });

  const countByStatus = Object.values(lastActivityByProspectId).reduce<{
    [key in OutreachEmailStatuses | ExtendedEmailStatuses]: number;
  }>(
    (acc, { status, didGuess }) => {
      acc[status] = (acc[status] || 0) + 1;

      if (status === OutreachEmailStatuses.Valid && !!didGuess) {
        acc[ExtendedEmailStatuses.EnrichedByBoring] =
          (acc[ExtendedEmailStatuses.EnrichedByBoring] || 0) + 1;
      }
      return acc;
    },
    {} as { [key in OutreachEmailStatuses | ExtendedEmailStatuses]: number }
  );

  return countByStatus;
};

export const getRangeStats = async ({
  organizationId,
  startDate: startDateArg,
  endDate: endDateArg,
}: {
  organizationId: string;
  startDate: Date;
  endDate: Date;
}): Promise<Result> => {
  const statsRef: DocumentReference<DocumentData> = doc(
    statsCollection,
    organizationId
  );

  const orgActivity = collection(statsRef, "activity");

  const result: Result = {};

  const dateRange = getStatsDateRange(startDateArg, endDateArg);

  const promises = [];

  const getStatsForDateRange = async (
    startDate: Date,
    endDate: Date,
    customKey?: string
  ) => {
    const key = customKey || endDate.toDateString();
    result[key] = {
      [OutreachEmailStatuses.Valid]: 0,
      [OutreachEmailStatuses.Invalid]: 0,
      [OutreachEmailStatuses.Questionable]: 0,
      prospectsChecked: 0,
      enrichedByBoring: 0,
    };

    const data = await getUniqueProspectCountsForDateRange({
      allActivity: orgActivity,
      startDate,
      endDate,
    });

    result[key] = {
      ...result[key],
      ...data,
    };

    result[key].prospectsChecked = Object.values(data).reduce(
      (acc, stat) => acc + stat,
      0
    );
  };

  promises.push(getStatsForDateRange(startDateArg, endDateArg, "totals"));

  for (const endDate of dateRange) {
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 1);
    promises.push(getStatsForDateRange(startDate, endDate));
  }

  await Promise.all(promises);
  return result;
};
