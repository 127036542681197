import React, { FunctionComponent } from "react";

const Loading: FunctionComponent<{ size?: "sm" }> = ({ size = "sm" }) => {
  return (
    <span
      className={`spinner-border spinner-border-${size}`}
      role="status"
      aria-hidden="true"
    />
  );
};

export default Loading;
