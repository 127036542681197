import React from "react";
import { css, styled } from "@mui/system";
import { StatusColors, SHADOWS, ChartValuesShapes } from "../../common/consts";
import {
  COLORS,
  RADII,
  SPACINGS,
  ChartLegendLabels,
} from "../../common/consts";
import { EmailStatuses } from "../../types/organizations";
import { SquareSvg } from "./Shapes";

export type Percentages = Record<string, number>;

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    name: string;
    payload: { percentages: Percentages };
  }>;
  label?: string;
}

interface LegendShapeWrapperProps {
  color: string;
}

const CustomTooltipWrapper = styled("div")`
  color: ${COLORS.backgroundPrimary};
  padding: ${SPACINGS.md};
  border-radius: ${RADII.md};
  background-color: ${COLORS.backgroundBrandDark};
  box-shadow: ${SHADOWS.medium};
`;

const LegendItem = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: ${SPACINGS.xs};
`;

export const LegendShapeWrapper = styled("div")<LegendShapeWrapperProps>`
  ${({ color }) => css`
    color: ${color};
  `};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${SPACINGS.sm};
`;

export const getColorByEntryName = (name: string): string => {
  return StatusColors[name as EmailStatuses] || COLORS.borderPrimary;
};

export const getLabelByEntryName = (name: string): string => {
  return ChartLegendLabels[name as EmailStatuses] || name;
};

export const getShapeByEntryName = (name: string): React.FC => {
  return ChartValuesShapes[name as EmailStatuses] || SquareSvg;
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <CustomTooltipWrapper>
        <p className="mb-1">{`${label}`}</p>
        {payload.map((entry, index) => {
          const percentage = entry.payload.percentages[entry.name] || 0;
          const ShapeComponent = getShapeByEntryName(entry.name);
          const entryColor = getColorByEntryName(entry.name);
          return (
            <LegendItem key={`item-${index}`}>
              <LegendShapeWrapper color={entryColor}>
                <ShapeComponent />
              </LegendShapeWrapper>
              {`${getLabelByEntryName(entry.name)}: ${percentage}%`}
            </LegendItem>
          );
        })}
      </CustomTooltipWrapper>
    );
  }
  return null;
};

export default CustomTooltip;
