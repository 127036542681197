import React, { FunctionComponent, useState, useEffect } from "react";
import Card from "../../components/Display/Card";
import Text, { TextVariant } from "../../components/Display/Text";
import Select from "../../components/DataInput/Select";
import Button, {
  ButtonVariants,
  ButtonSizes,
} from "../../components/Display/Button";
import {
  OrganizationStats,
  OutreachEmailStatuses,
  ExtendedEmailStatuses,
} from "../../types/organizations";
import MainChart, { MainChartData } from "./MainChart";
import { styled } from "@mui/system";
import {
  RADII,
  StatusColors,
  COLORS,
  SPACINGS,
  SHADOWS,
  FONT_WEIGHTS,
} from "../../common/consts";
import { getRangeStats } from "../../services/firebase";
import {
  getRelativeDate,
  formatLongDate,
  generateDynamicTimeframeOptions,
} from "../../common/utils/date-time";
import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh.svg";
import Loading from "../../components/Display/Loading";
import { calculateStatsPercentages } from "../../common/utils/number";

export interface TimeframeOption {
  value: string;
  label: string;
  startDate: Date;
  endDate: Date;
}

const timeframeOptions: TimeframeOption[] = [
  {
    value: "this-week",
    label: "This week",
    startDate: getRelativeDate(new Date(), 7),
    endDate: new Date(),
  },
  {
    value: "last-week",
    label: "Last week",
    startDate: getRelativeDate(new Date(), 14),
    endDate: getRelativeDate(new Date(), 7),
  },
  {
    value: "this-month",
    label: "This month",
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
  },
  {
    value: "last-month",
    label: "Last month",
    startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
  },
  ...generateDynamicTimeframeOptions(),
];

const StatCard = styled("div")`
  border-radius: ${RADII.md};
  padding: ${SPACINGS.sm};
  min-width: 11.25rem;
`;

const StyledButton = styled(Button)`
  background-color: ${COLORS.backgroundBrand};
  display: inline-block;
  padding: ${SPACINGS.xs};
`;

const StyledRefreshIcon = styled(RefreshIcon)`
  fill: ${COLORS.foregroundBrand};
  width: 1.4rem;
  height: 1.4rem;
`;

const TitleText = styled(Text)`
  color: ${COLORS.foregroundBrand};
  padding-bottom: ${SPACINGS.sm};
  font-weight: ${FONT_WEIGHTS.medium};
  font-size: 0.813rem;
  letter-spacing: 0.025rem;
`;

const SubtitleText = styled(Text)`
  color: ${COLORS.foregroundSecondary};
  font-size: 0.813rem;
  margin-top: ${SPACINGS.xs};
`;

const NoPaddingCard = styled(Card)`
  padding: ${SPACINGS.none};
`;

const PaddingDiv = styled("div")`
  padding: ${SPACINGS.lg};
`;

const HorizontalLine = styled(PaddingDiv)`
  border-bottom: 1px solid ${COLORS.borderPrimary};
  padding-bottom: ${SPACINGS.xl};
`;

const ChartContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid ${COLORS.borderPrimary};
  overflow: hidden;
`;

const ColoredPaddingDiv = styled(HorizontalLine)`
  background-color: ${COLORS.backgroundBrandLight};
  color: ${COLORS.foregroundBrand};
  box-shadow: ${SHADOWS.inset} ${SHADOWS.brand};
`;

const StyledTitle = styled(Text)`
  line-height: 1.43;
`;

interface Props {
  organizationId?: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const Insights: FunctionComponent<Props> = ({ organizationId, setError }) => {
  const [shouldRefetchStats, setShouldRefetchStats] = useState(true);
  const [selectedTimeframe, setSelectedTimeframe] = useState<TimeframeOption>(
    timeframeOptions[0]
  );
  const [isInitialFetching, setIsInitialFetching] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [globalStats, setGlobalStats] = useState<OrganizationStats>({
    prospectsChecked: 0,
    enrichedByBoring: 0,
    invalid: 0,
    valid: 0,
    questionable: 0,
    noEmail: 0,
  });
  const [globalStatsPercentage, setGlobalStatsPercentage] =
    useState<OrganizationStats>({
      prospectsChecked: 0,
      enrichedByBoring: 0,
      invalid: 0,
      valid: 0,
      questionable: 0,
      noEmail: 0,
    });

  const [stats, setStats] = useState<MainChartData>(
    Array.from({ length: 7 }, (_, i) => ({
      date: formatLongDate(getRelativeDate(new Date(), 6 - i)),
      [OutreachEmailStatuses.Valid]: 0,
      [OutreachEmailStatuses.Invalid]: 0,
      [OutreachEmailStatuses.Questionable]: 0,
      [ExtendedEmailStatuses.NoEmail]: 0,
      [ExtendedEmailStatuses.EnrichedByBoring]: 0,
    }))
  );

  const handleTimeframeChange = (value: TimeframeOption) => {
    setSelectedTimeframe(value);
    setShouldRefetchStats(true);
  };

  useEffect(() => {
    if (organizationId && shouldRefetchStats) {
      const getStats = async () => {
        const { startDate, endDate } = selectedTimeframe;

        const rangeStats = await getRangeStats({
          organizationId,
          startDate,
          endDate,
        });

        const {
          prospectsChecked: prospectsCheckedDb = 0,
          valid: validFromDb = 0,
          invalid: invalidFromDb = 0,
          questionable: questionableFromDb = 0,
          enrichedByBoring: enrichedByBoringDb = 0,
          // noEmail: missingFromDb = 0,
        } = rangeStats.totals || {
          prospectsChecked: 0,
          valid: 0,
          invalid: 0,
          questionable: 0,
          enrichedByBoring: 0,
        };

        const globalStats: OrganizationStats = {
          prospectsChecked: prospectsCheckedDb,
          enrichedByBoring: enrichedByBoringDb,
          valid: validFromDb,
          invalid: invalidFromDb,
          questionable: questionableFromDb,
          noEmail: 0,
        };

        setGlobalStats(globalStats);
        setGlobalStatsPercentage(calculateStatsPercentages(globalStats));

        delete rangeStats["totals"];

        const stats: MainChartData = Object.keys(rangeStats).map((key) => ({
          date: formatLongDate(new Date(key)),
          [OutreachEmailStatuses.Valid]: rangeStats[key].valid,
          [OutreachEmailStatuses.Invalid]: rangeStats[key].invalid,
          [OutreachEmailStatuses.Questionable]: rangeStats[key].questionable,
          [ExtendedEmailStatuses.NoEmail]: 0,
          [ExtendedEmailStatuses.EnrichedByBoring]:
            rangeStats[key].enrichedByBoring,
        }));

        setStats(stats);
        setShouldRefetchStats(false);
        setIsInitialFetching(false);
      };

      getStats();
    }
  }, [organizationId, shouldRefetchStats, selectedTimeframe]);

  const displayedStats = isHovering ? globalStats : globalStatsPercentage;
  const suffix = isHovering ? "" : "%";

  return (
    <div className="d-flex flex-column gap-4">
      <Card>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <StyledTitle variant={TextVariant.h5}>
              Email verification report
            </StyledTitle>
            <Text variant={TextVariant.small}>
              See how well your email data tool is performing
            </Text>
          </div>
          <div className="d-flex align-items-center mt-3">
            <Select
              value={selectedTimeframe.value}
              options={timeframeOptions}
              onChange={(e) => {
                const { value } = e.target;
                const selectedOption = timeframeOptions.find(
                  (option) => option.value === value
                );
                if (selectedOption) {
                  handleTimeframeChange(selectedOption);
                }
              }}
            />
            <StyledButton
              variant={ButtonVariants.light}
              size={ButtonSizes.sm}
              className="ms-2"
              onClick={() => setShouldRefetchStats(true)}
              startIcon={<StyledRefreshIcon />}
            />
          </div>
        </div>
      </Card>
      <NoPaddingCard className="d-flex">
        <div
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <HorizontalLine className="d-flex flex-column">
            <TitleText variant={TextVariant.small} className="text-uppercase">
              Prospects verified
            </TitleText>
            <StatCard>
              <Text variant={TextVariant.h4} color={COLORS.foregroundBrand}>
                {globalStats.prospectsChecked}
              </Text>
            </StatCard>
            <SubtitleText variant={TextVariant.small}>
              Total prospects verified
            </SubtitleText>
          </HorizontalLine>
          <ColoredPaddingDiv className="d-flex flex-column">
            <TitleText variant={TextVariant.small} className="text-uppercase">
              Email data accuracy
            </TitleText>
            <div className="d-flex flex-row gap-4">
              <StatCard>
                <Text
                  variant={TextVariant.h4}
                  color={StatusColors[OutreachEmailStatuses.Valid]}
                >
                  {displayedStats.valid}
                  {suffix}
                </Text>
                <SubtitleText variant={TextVariant.small}>Valid</SubtitleText>
              </StatCard>
              <StatCard>
                <Text
                  variant={TextVariant.h4}
                  color={StatusColors[OutreachEmailStatuses.Invalid]}
                >
                  {displayedStats.invalid}
                  {suffix}
                </Text>
                <SubtitleText variant={TextVariant.small}>Invalid</SubtitleText>
              </StatCard>
            </div>
            <div className="d-flex flex-row gap-4">
              <StatCard>
                <Text
                  variant={TextVariant.h4}
                  color={StatusColors[OutreachEmailStatuses.Questionable]}
                >
                  {displayedStats.questionable}
                  {suffix}
                </Text>
                <SubtitleText variant={TextVariant.small}>
                  Questionable
                </SubtitleText>
              </StatCard>
              <StatCard>
                <Text
                  variant={TextVariant.h4}
                  color={StatusColors[ExtendedEmailStatuses.NoEmail]}
                >
                  {displayedStats.noEmail}
                  {suffix}
                </Text>
                <SubtitleText variant={TextVariant.small}>Missing</SubtitleText>
              </StatCard>
            </div>
          </ColoredPaddingDiv>
          <PaddingDiv className="d-flex flex-column">
            <TitleText variant={TextVariant.small} className="text-uppercase">
              Boring ROI
            </TitleText>
            <div className="d-flex flex-row gap-4">
              {/* TODO: Add the BORING ROI fields */}
              <StatCard>
                <Text variant={TextVariant.h4}>-</Text>
                <SubtitleText variant={TextVariant.small}>
                  Bounce rate reduction
                </SubtitleText>
              </StatCard>
              {/* TODO: Add the BORING ROI fields */}
              <StatCard>
                <Text
                  variant={TextVariant.h4}
                  color={StatusColors[ExtendedEmailStatuses.EnrichedByBoring]}
                >
                  {displayedStats.enrichedByBoring}
                  {suffix}
                </Text>
                <SubtitleText variant={TextVariant.small}>
                  Emails enriched
                </SubtitleText>
              </StatCard>
            </div>
          </PaddingDiv>
        </div>
        <ChartContainer className="position-relative flex-grow-1 py-3">
          {isInitialFetching ? <Loading /> : <MainChart data={stats} />}
        </ChartContainer>
      </NoPaddingCard>
    </div>
  );
};

export default Insights;
