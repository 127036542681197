import React from "react";
import { COLORS } from "../../common/consts";

const DiamondSvg: React.FC = () => (
  <svg
    width="10"
    height="10"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      stroke={COLORS.foregroundWhite}
      d="M 5 0 L 0 5 L 5 10 L 10 5 z"
    ></path>
  </svg>
);

const TriangleSvg: React.FC = () => (
  <svg
    width="10"
    height="10"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      stroke={COLORS.foregroundWhite}
      d="M 5,0.1 9.9,9.9 0.1,9.9 z"
    ></path>
  </svg>
);

const SquareSvg: React.FC = () => (
  <svg
    width="10"
    height="10"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      stroke={COLORS.foregroundWhite}
      d="M.5.5h9v9h-9z"
    ></path>
  </svg>
);

const CircleSvg: React.FC = () => (
  <svg
    width="10"
    height="10"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="5"
      cy="5"
      r="4.5"
      fill="currentColor"
      stroke={COLORS.foregroundWhite}
    ></circle>
  </svg>
);

export { DiamondSvg, TriangleSvg, SquareSvg, CircleSvg };
