import { TimeframeOption } from "../../pages/Dashboard/Insights";

export function getRelativeDate(date: Date, daysDiff: number): Date {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - daysDiff);
  return new Date(newDate);
}

export function formatLongDate(date: Date): string {
  return date.toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });
}

export function formatShortDate(dateString: string): string {
  const date = new Date(dateString);
  const month = date.toLocaleString("en-US", { month: "short" });
  const day = date.getDate();
  return `${month} ${day}`;
}

export const generateDynamicTimeframeOptions = (): TimeframeOption[] => {
  const options: TimeframeOption[] = [];
  const currentDate = new Date();

  for (let i = 1; i < 7; i++) {
    const date = new Date(currentDate);
    date.setMonth(date.getMonth() - i - 1);
    const monthName = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    options.push({
      value: `${monthName.toLowerCase()}-${year}`,
      label: `${monthName} ${year}`,
      startDate: new Date(year, date.getMonth(), 1),
      endDate: new Date(year, date.getMonth() + 1, 0),
    });
  }

  return options;
};

export function getStatsDateRange(startDate: Date, endDate: Date): Date[] {
  const dates = [];

  for (
    let currDate = new Date(startDate);
    currDate <= endDate;
    currDate.setDate(currDate.getDate() + 1)
  ) {
    dates.push(new Date(currDate));
  }

  return dates;
}
