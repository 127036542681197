import { OrganizationStats } from "../../types/organizations";

export const round = (value: number, fixed = 1): number => {
  return Math.round(value * 10 ** fixed) / 10 ** fixed;
};

export const calculatePercentage = (value: number, total: number): number =>
  total === 0 ? 0 : (value / total) * 100;

export const calculateStatsPercentages = (
  stats: OrganizationStats
): OrganizationStats => {
  const total = stats.prospectsChecked || 0;

  return {
    prospectsChecked: total,
    valid: round(calculatePercentage(stats.valid || 0, total)),
    invalid: round(calculatePercentage(stats.invalid || 0, total)),
    questionable: round(calculatePercentage(stats.questionable || 0, total)),
    noEmail: round(calculatePercentage(stats.noEmail || 0, total)),
    enrichedByBoring: round(
      calculatePercentage(stats.enrichedByBoring || 0, total)
    ),
  };
};
