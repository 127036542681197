import React, { FunctionComponent } from "react";
import { styled } from "@mui/system";
import { SPACINGS } from "../../common/consts";

const StyledContainer = styled("div")`
  position: absolute;
  bottom: ${SPACINGS.xl};
  right: ${SPACINGS.xl};
`;

const EnvLabel: FunctionComponent<{ env: string }> = ({ env }) => {
  return (
    <StyledContainer>
      <div className="badge badge-brand">{env}</div>
    </StyledContainer>
  );
};

export default EnvLabel;
