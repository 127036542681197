import React, { FunctionComponent, ReactNode } from "react";
import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import { COLORS, FONTS, RADII } from "../../common/consts";

export interface SelectOption {
  value: string;
  label?: ReactNode;
}

interface SelectProps extends Omit<MuiSelectProps<string>, "options"> {
  options: SelectOption[];
}

const StyledMenuItem = styled(MenuItem)`
  && {
    font: ${FONTS.small};
    color: ${COLORS.foregroundTertiary};
    background-color: ${COLORS.backgroundWhite};
    &.Mui-selected {
      background: ${COLORS.backgroundBrandLight};
    }
    &:hover {
      background-color: ${COLORS.backgroundSecondary};
    }
  }
`;

const StyledSelect = styled(MuiSelect<string>)`
  && {
    border-radius: ${RADII.md};
    font: ${FONTS.small};
    color: ${COLORS.foregroundTertiary};
    background-color: ${COLORS.backgroundWhite};
    border: 1px solid ${COLORS.borderTertiary};
    &:hover {
      border: 1px solid ${COLORS.foregroundBrand};
    }
    &.Mui-focused {
      border: 1px solid ${COLORS.foregroundBrand};
      background-color: ${COLORS.backgroundBrandTab};
    }
    & .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
    &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
  .MuiSvgIcon-root {
    color: ${COLORS.foregroundSecondary};
  }
`;

const Select: FunctionComponent<SelectProps> = ({
  label,
  options,
  variant = "outlined",
  ...rest
}) => {
  return (
    <>
      <FormControl size="small">
        {label && <InputLabel>{label}</InputLabel>}
        <StyledSelect variant={variant} {...rest}>
          {options?.map(({ value, label }, index) => (
            <StyledMenuItem key={`select-option-${index}`} value={value}>
              {label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </>
  );
};

export default Select;
