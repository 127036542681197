import React, { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import useLocalStorage from "../../common/hooks/useLocalStorage";
import {
  getOutreachAuthUrl,
  reload,
  signUserOut,
  validateOutreachAuthCode,
} from "../../services/firebase";
import { signedInUserAtom } from "../../state/atoms";
import { Errors } from "../../types/errors";

import Button, { ButtonVariants } from "../../components/Display/Button";
import FormHelper, { Severity } from "../../components/DataInput/FormHelper";
import Text, { TextVariant } from "../../components/Display/Text";
import Animation from "../../components/Display/Animation";

const ConnectOutreach: FunctionComponent = () => {
  const [outreachAuthCode, setOutreachAuthCode] = useLocalStorage(
    "outreachAuthCode",
    ""
  );
  const signedInUser = useRecoilValue(signedInUserAtom);
  const navigate = useNavigate();

  const [isProcessing, setIsProcessing] = useState(false);
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const getConnectOutreachUrl = async () => {
      try {
        setIsProcessing(true);
        setError("");
        const { result, status } = await getOutreachAuthUrl();

        if (status === 200 && result?.outreachAuthUrl) {
          setUrl(result.outreachAuthUrl);
        } else {
          setError(
            `Error connecting to Outreach (Code: ${Errors.CONNECT_OUTREACH_GENERATE_URL_FUNCTION_ERROR}). Please contact us for help.`
          );
        }
      } catch (error) {
        setError(
          `Error connecting to Outreach (Code: ${Errors.CONNECT_OUTREACH_GENERATE_URL_FUNCTION_FAILURE}). Please contact us for help.`
        );
      } finally {
        setIsProcessing(false);
      }
    };

    getConnectOutreachUrl();
  }, []);

  useEffect(() => {
    if (signedInUser?.isOutreachAuthorized) {
      navigate("/app/dashboard");
      return;
    }

    const updateOutreachAccessToken = async () => {
      try {
        setIsProcessing(true);
        setError("");
        if (signedInUser && outreachAuthCode) {
          const { status, result } =
            await validateOutreachAuthCode(outreachAuthCode);
          setOutreachAuthCode("");
          if (status === 200 && result?.isOutreachAuthorized) {
            reload();
          } else {
            setError(
              `Error connecting to Outreach (Code: ${Errors.CONNECT_OUTREACH_UPDATE_ORG_FUNCTION_ERROR}). Please contact us for help.`
            );
          }
        }
      } catch (error) {
        setError(
          `Error connecting to Outreach (Code: ${Errors.CONNECT_OUTREACH_UPDATE_ORG_FUNCTION_FAILURE}). Please contact us for help.`
        );
      } finally {
        setIsProcessing(false);
      }
    };

    updateOutreachAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outreachAuthCode, signedInUser]);

  const openOutreachAuthUrl = () => {
    if (url) {
      const width = window.innerWidth * 0.75;
      const height = window.innerHeight * 0.75;
      const params = `width=${width},height=${height}`;

      const newWindow = window.open(url, "Authorize Outreach", params);
      newWindow?.focus();
    }
  };

  return (
    <Animation className="col-12">
      <div className="d-flex h-100 align-items-center justify-content-center m-auto">
        <div className="w-75 w-lg-400px">
          <Text variant={TextVariant.h4} className="mb-3 text-center">
            Connect to your Outreach account
          </Text>
          <div className="mb-3">
            <Button
              fullWidth
              variant={ButtonVariants.brand}
              type="submit"
              disabled={isProcessing || !url}
              onClick={openOutreachAuthUrl}
              loading={isProcessing}
              startIcon={<i className="fa-solid fa-plug" />}
            >
              Connect to Outreach
            </Button>
          </div>
          <Text variant={TextVariant.small} className="text-center">
            I will continue later,{" "}
            <span onClick={signUserOut} className="btn-link cursor-pointer">
              sign out
            </span>{" "}
            for now.
          </Text>
          <FormHelper helperText={error} severity={Severity.error} />
        </div>
      </div>
    </Animation>
  );
};

export default ConnectOutreach;
