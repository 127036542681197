import React, { FunctionComponent } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { InputLabel } from "@mui/material";
import { styled } from "@mui/system";
import { RADII, COLORS } from "../../common/consts";

const StyledTextField = styled("div")`
  .MuiInputBase-root {
    border-radius: ${RADII.md};
    &:hover {
      .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${COLORS.borderBrandLight};
      }
    }
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${COLORS.borderBrandLight};
      }
    }
  }
`;

const Input: FunctionComponent<TextFieldProps> = ({ label, ...rest }) => {
  return (
    <>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <StyledTextField>
        <TextField size="small" fullWidth {...rest} />
      </StyledTextField>
    </>
  );
};

export default Input;
