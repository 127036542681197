import React, { FunctionComponent } from "react";
import { useRecoilValue } from "recoil";

import { signUserOut } from "../../services/firebase";
import { signedInUserAtom } from "../../state/atoms";
import Text, { TextVariant } from "../../components/Display/Text";

const WaitForOnboarding: FunctionComponent = () => {
  const signedInUser = useRecoilValue(signedInUserAtom);

  if (!signedInUser) {
    return <></>;
  }

  const { email, firstName, lastName } = signedInUser;

  return (
    <div className="col-12">
      <div className="d-flex h-100 align-items-center justify-content-center m-auto">
        <div className="w-75 w-lg-400px">
          <Text variant={TextVariant.h4} className="mb-1">
            Hi, {firstName || lastName || "stranger"}! 👋
          </Text>
          <Text className="mb-1">
            We will contact you at {email} to complete your onboarding.
          </Text>
          <div className="text-small">
            <span
              onClick={signUserOut}
              className="btn-link cursor-pointer link"
            >
              Sign out
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitForOnboarding;
