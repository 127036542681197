import React, { FunctionComponent, HTMLAttributes } from "react";
import { Link as RouterLink } from "react-router-dom";

export enum LinkColors {
  primary = "primary",
  secondary = "secondary",
  success = "success",
  danger = "danger",
  warning = "warning",
  info = "info",
  light = "light",
  dark = "dark",
}

interface LinkProps extends Omit<HTMLAttributes<HTMLElement>, "href"> {
  path: string;
  external?: boolean;
  color?: LinkColors;
}

const Link: FunctionComponent<LinkProps> = ({
  path,
  external,
  color = LinkColors.primary,
  className: classNameProp,
  children,
  ...rest
}) => {
  const className = `link link-${color} ${classNameProp || ""}`;

  if (external) {
    return (
      <a
        className={className}
        target="_blank"
        rel="noreferrer"
        href={path}
        {...rest}
      >
        {children}
      </a>
    );
  } else {
    return (
      <RouterLink className={className} to={path} {...rest}>
        {children}
      </RouterLink>
    );
  }
};

export default Link;
