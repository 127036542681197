import React, { FunctionComponent, HTMLAttributes } from "react";
import { styled } from "@mui/system";
import { COLORS, SHADOWS, SPACINGS, RADII } from "../../common/consts";

const StyledContainer = styled("div")`
  overflow-y: auto;
  background-color: ${COLORS.backgroundPrimary};
  box-shadow: ${SHADOWS.small};
  border-radius: ${RADII.md};
  padding: ${SPACINGS.lg};
`;

const Card: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <StyledContainer className={className} {...rest}>
      {children}
    </StyledContainer>
  );
};

export default Card;
