export enum OrganizationStatus {
  TRIAL = "trial",
  PAID = "paid",
  SUSPENDED = "suspended",
}

export enum prospectRunStatus {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export interface Organization {
  id: string;
  name?: string;
  status?: OrganizationStatus;
  isOutreachAuthorized?: boolean;
  outreachAuthentication?: OutreachAuthentication;
  prospectsWebhookId?: number;
  isEmailGuessingEnabled?: boolean;
}

export interface OutreachAuthentication {
  authorizationCode: string;
  accessToken?: string;
  refreshToken?: string;
  expiresAt?: number; //TODO: deprecated - we should use accessTokenExpiresAt, remove this
  accessTokenExpiresAt?: number;
}

export interface StatProspect {
  prospectId: string;
  email: string;
  emailCheckResult: EmailCheckResult;
}

export enum OutreachEmailStatuses {
  Valid = "valid",
  Invalid = "invalid",
  Questionable = "questionable",
}

export enum ExtendedEmailStatuses {
  NoEmail = "noEmail",
  EnrichedByBoring = "enrichedByBoring",
}

export type EmailStatuses = OutreachEmailStatuses | ExtendedEmailStatuses;

export interface OrganizationStats {
  emailsChecked?: number;
  prospectsChecked?: number;
  enrichedByBoring?: number;
  [OutreachEmailStatuses.Valid]?: number;
  [OutreachEmailStatuses.Invalid]?: number;
  [OutreachEmailStatuses.Questionable]?: number;
  [ExtendedEmailStatuses.NoEmail]?: number;
}

export interface AbstractApiParam {
  text: string;
  value: boolean;
}

export interface AbstractApiResult {
  email: string;
  auto_correct: string;
  deliverability: string;
  quality_score: number;
  is_valid_format: AbstractApiParam;
  is_free_email: AbstractApiParam;
  is_disposable_email: AbstractApiParam;
  is_role_email: AbstractApiParam;
  is_catchall_email: AbstractApiParam;
  is_mx_found: AbstractApiParam;
  is_smtp_valid: AbstractApiParam;
}

export interface EmailCheckResult {
  email: string;
  score: number;
  isLikelyValid?: boolean;
  isSyntaxValid: boolean;
  isBurnerEmail?: boolean;
  isMxFound?: boolean;
  abstractApiResult?: AbstractApiResult;
}
