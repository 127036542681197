import React, { FunctionComponent, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Helmet } from "react-helmet-async";

import { signUserUp, upsertAppUser } from "../../services/firebase";
import { signupFormAtom } from "../../state/atoms";
import { canSubmitSignupFormSelector } from "../../state/selectors";
import { Errors } from "../../types/errors";
import mainImage from "../../assets/images/main-image.svg";
import TermsFooter from "./TermsFooter";
import Card from "../../components/Display/Card";
import Button, { ButtonVariants } from "../../components/Display/Button";
import Input from "../../components/DataInput/Input";
import FormHelper, { Severity } from "../../components/DataInput/FormHelper";
import Text, { TextVariant } from "../../components/Display/Text";
import Link from "../../components/Display/Link";
import Animation from "../../components/Display/Animation";

const Signup: FunctionComponent = () => {
  const [signupForm, setSignupForm] = useRecoilState(signupFormAtom);
  const canSubmitSignupForm = useRecoilValue(canSubmitSignupFormSelector);

  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (canSubmitSignupForm) {
      try {
        setIsProcessing(true);
        setError("");
        const { user } = await signUserUp(
          signupForm.email,
          signupForm.password
        );

        if (!user) {
          setError(
            `Error creating account (Code ${Errors.AUTH_SIGNUP_ERROR}). Please validate the data you have entered, and try again.`
          );

          return;
        }

        await upsertAppUser({
          uid: user.uid,
          email: user.email || signupForm.email,
          firstName: signupForm.name,
        });
      } catch (error) {
        setError(
          `Error creating account (Code ${Errors.AUTH_SIGNUP_FAILURE}). Please validate the data you have entered, and try again.`
        );
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const buttons = (
    <>
      <Link path="/auth/login">Log in</Link>
      <Button
        variant={ButtonVariants.brand}
        type="submit"
        disabled={!canSubmitSignupForm || isProcessing}
        loading={isProcessing}
      >
        Sign up
      </Button>
    </>
  );

  return (
    <Card>
      <Animation>
        <Helmet>
          <title>Sign up | Boring</title>
        </Helmet>
        <div className="d-flex flex-column h-100 align-items-center justify-content-center m-auto">
          <form className="w-75 w-lg-400px" onSubmit={onSubmit}>
            <div className="d-flex w-100 align-items-center justify-content-center">
              <img src={mainImage} className="img-fluid" alt="Boring plugins" />
            </div>
            <Text variant={TextVariant.h4} className="mb-5">
              Sign up
            </Text>
            <div className="d-flex flex-column gap-3 mb-3">
              <div>
                <Input
                  label="Name"
                  type="text"
                  placeholder="Your name"
                  value={signupForm.name}
                  required
                  onChange={(e) =>
                    setSignupForm({ ...signupForm, name: e.target.value })
                  }
                />
              </div>
              <div>
                <Input
                  label="Email address"
                  type="email"
                  placeholder="name@example.com"
                  value={signupForm.email}
                  required
                  onChange={(e) =>
                    setSignupForm({ ...signupForm, email: e.target.value })
                  }
                />
              </div>
              <div>
                <Input
                  label="Password (min. 8 chars)"
                  type="password"
                  placeholder="********"
                  InputProps={{ inputProps: { min: 8 } }}
                  required
                  value={signupForm.password}
                  onChange={(e) =>
                    setSignupForm({ ...signupForm, password: e.target.value })
                  }
                />
              </div>
              <div>
                <Input
                  label="Confirm password"
                  type="password"
                  placeholder="********"
                  value={signupForm.passwordConfirmation}
                  InputProps={{ inputProps: { min: 8 } }}
                  required
                  onChange={(e) =>
                    setSignupForm({
                      ...signupForm,
                      passwordConfirmation: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="d-none d-sm-flex justify-content-between align-items-center">
              {buttons}
            </div>
            <div className="d-flex flex-column-reverse gap-3 d-sm-none text-center">
              {buttons}
            </div>
            <FormHelper helperText={error} severity={Severity.error} />
            <TermsFooter />
          </form>
        </div>
      </Animation>
    </Card>
  );
};

export default Signup;
