import React, { FunctionComponent, ReactNode, HTMLAttributes } from "react";

export enum Severity {
  error = "error",
  warning = "warning",
  info = "info",
}

interface FormHelperProps extends HTMLAttributes<HTMLDivElement> {
  helperText?: ReactNode;
  severity?: Severity;
}

const getClassName = (severity: Severity) => {
  switch (severity) {
    case Severity.error:
      return "text-danger";
    case Severity.warning:
      return "text-warning";
    case Severity.info:
    default:
      return "text-info";
  }
};

const FormHelper: FunctionComponent<FormHelperProps> = ({
  helperText,
  severity = Severity.info,
  ...props
}) => {
  return (
    <>
      {helperText && (
        <div
          className={`my-2 text-center text-small ${getClassName(severity)}`}
          {...props}
        >
          <hr />
          {helperText}
        </div>
      )}
    </>
  );
};

export default FormHelper;
