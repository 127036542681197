import React, { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";

const AuthPageContainer: FunctionComponent = () => {
  return (
    <div className="row m-0 p-0 h-100">
      <Outlet />
    </div>
  );
};

export default AuthPageContainer;
