import React, { FunctionComponent } from "react";
import { styled } from "@mui/system";
import { SPACINGS } from "../../common/consts";

const StyledContainer = styled("div")`
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: ${SPACINGS.xs} ${SPACINGS.lg} ${SPACINGS.lg} ${SPACINGS.xs};
`;

interface Props {
  children?: React.ReactNode;
}

const AppContainer: FunctionComponent<Props> = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

export default AppContainer;
