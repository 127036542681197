import React, { FunctionComponent, useEffect, useState } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Helmet } from "react-helmet-async";

import {
  getAppUser,
  getMyOrganization,
  onAuthStateChangedHook,
} from "../services/firebase";
import { isDevelopment, isStaging } from "../services/config";
import { getRoutes } from "../services/routes";
import { signedInUserAtom } from "../state/atoms";
import Loading from "../components/Display/Loading";
import EnvLabel from "../components/Layout/EnvLabel";
import AppContainer from "../components/Layout/AppContainer";

const App: FunctionComponent = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [waitingForAuth, setWaitingForAuth] = useState(true);
  const [signedInUser, setSignedInUser] = useRecoilState(signedInUserAtom);

  const env = isDevelopment ? "Development" : isStaging ? "Staging" : null;

  const content = useRoutes(getRoutes(!!signedInUser));

  useEffect(() => {
    onAuthStateChangedHook(async (authUser) => {
      try {
        setWaitingForAuth(true);

        if (!authUser) {
          setSignedInUser(null);
          return;
        }

        const {
          uid,
          email: authEmail,
          emailVerified: authEmailVerified,
        } = authUser;

        if (!authEmail) {
          console.error("Authenticated user has no email");
          return;
        }

        const dbUser = await getAppUser(uid);
        const { status: organizationRequestStatus, result: organization } =
          await getMyOrganization();

        const {
          id: organizationId,
          name: organizationName,
          status: organizationStatus,
          isOutreachAuthorized,
          prospectsWebhookId,
        } = organization || {};

        const {
          firstName: dbFirstName,
          lastName: dbLastName,
          phoneNumber: dbPhoneNumber,
        } = dbUser || {};

        const user = {
          uid,
          email: authEmail,
          emailVerified: authEmailVerified || false,
          firstName: dbFirstName,
          lastName: dbLastName,
          phoneNumber: dbPhoneNumber,
          organizationId,
          organizationName,
          organizationStatus,
          isOutreachAuthorized,
          isProspectWebhookCreated: !!prospectsWebhookId,
        };
        setSignedInUser(user);

        if (organizationRequestStatus !== 200) {
          console.warn("No organization data found in database");
        }
        if (!dbUser) {
          console.warn("No user data found in database");
          navigate("/app/complete-profile");
          return;
        }
        if (user.firstName) {
          if (!user.organizationId) {
            navigate("/app/continue-onboarding");
            return;
          }
          if (pathname.startsWith("/oauth")) {
            return;
          }

          if (!user.isOutreachAuthorized) {
            navigate("/app/connect-outreach");
            return;
          }
          navigate("/app/dashboard");
          return;
        }
      } catch (err) {
        console.error("Error logging in", { err });
        setSignedInUser(null);
      } finally {
        setWaitingForAuth(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return waitingForAuth ? (
    <div className="d-flex w-100 min-h-100vh align-items-center justify-content-center">
      <Loading />
    </div>
  ) : (
    <>
      <Helmet>
        <title>Dashboard | Boring</title>
      </Helmet>
      <AppContainer>
        {env && <EnvLabel env={env} />}
        {content}
      </AppContainer>
    </>
  );
};

export default App;
